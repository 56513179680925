const things = document.querySelectorAll(".things")

things.forEach(thing => {
  const categories = thing.querySelectorAll(".things__category")
  const items = thing.querySelectorAll(".things__item")

  let groupedItems = {}
  items.forEach(item => {
    const category = item.dataset.category
    if (typeof groupedItems[category] === "undefined") {
      groupedItems[category] = []
    }
    groupedItems[category] = groupedItems[category].concat(item)
  })

  categories.forEach(category => {
    const items = groupedItems[category.dataset.category]
    category.addEventListener("click", () => {
      items.forEach(item => {
        item.classList.toggle("things__item_open")
      })
      category.classList.toggle("things__category_open")
    })
  })
});
