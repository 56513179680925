document.querySelectorAll('.slider').forEach(slider => {
  const slides = slider.querySelector('.slider__slides');
  let slideWidth = slider.querySelector('.slider__slide').clientWidth; // made let to update on resize
  const totalSlides = slider.querySelectorAll('.slider__slide').length;
  const dots = slider.querySelectorAll(`.slider__dot`);
  const prevButton = slider.querySelector('.slider__control_to_prev');
  const nextButton = slider.querySelector('.slider__control_to_next');
  let currentSlide = 1;
  let startTouch = 0; // For touch start position

  const updateSlides = () => {
    slides.style.transform = `translateX(${-slideWidth * (currentSlide - 1)}px)`;
    updateDots();
    updateControls();
  };

  const nextSlide = () => {
    currentSlide = (currentSlide % totalSlides) + 1;
    updateSlides();
  };

  const prevSlide = () => {
    currentSlide = (currentSlide === 1) ? totalSlides : currentSlide - 1;
    updateSlides();
  };

  const goToSlide = (slideNumber) => {
    currentSlide = slideNumber;
    updateSlides();
  };

  const updateDots = () => {
    dots.forEach((dot, index) => {
      dot.classList.toggle('slider__dot_active', index + 1 === currentSlide);
    });
  };

  dots.forEach((dot, index) => {
    dot.addEventListener('click', () => goToSlide(index + 1));
  });

  const updateControls = () => {
    prevButton.style.visibility = (currentSlide === 1) ? 'hidden' : 'visible';
    nextButton.style.visibility = (currentSlide === totalSlides) ? 'hidden' : 'visible';
  };

  // Resize event listener to update slide width and slides
  window.addEventListener('resize', () => {
    slideWidth = slider.querySelector('.slider__slide').clientWidth;
    updateSlides();
  });

  // Touch events for swiping on mobile
  slider.addEventListener('touchstart', (e) => {
    startTouch = e.touches[0].clientX;
  });

  slider.addEventListener('touchend', (e) => {
    const endTouch = e.changedTouches[0].clientX;
    if (startTouch - endTouch > 50) { // Swipe left
      nextSlide();
    } else if (startTouch - endTouch < -50) { // Swipe right
      prevSlide();
    }
  });

  prevButton.addEventListener('click', prevSlide);
  nextButton.addEventListener('click', nextSlide);

  // Initialize slides
  updateSlides();
});
