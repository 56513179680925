const path = document.querySelector(".path");
const pathItems = path.querySelectorAll(".path__item")

const pathObserver = new IntersectionObserver(entries => {
  updatePath()

  entries.forEach(entry => {
    if (entry.isIntersecting) {
      return window.addEventListener("scroll", updatePath, { passive: true })
    }

    window.removeEventListener("scroll", updatePath, { passive: true })
  })
},  {
  root: document,
  rootMargin: "100px 0px 0px 0px",
  threshold: 0,
})

function updatePath() {
  const { y } = path.getBoundingClientRect()

  const top = Math.max(0, window.innerHeight / 2 - y).toFixed(0)

  path.style.setProperty("--line-height", `${top}px`)

  pathItems.forEach(item => {
    if (item.offsetTop <= top) return item.classList.add("path__item_activated")

    item.classList.remove("path__item_activated")
  })
}

pathObserver.observe(path);
