const page = document.querySelector(".page")
const menu = document.querySelector(".menu")

const control = menu.querySelector('.menu__control')
const links = menu.querySelectorAll(".menu__link")

const toggleMenu = () => {
  page.classList.toggle("page_lock")
  menu.classList.toggle("menu_open");
}

control.addEventListener("click", () => toggleMenu())

links.forEach(link => {
  link.addEventListener("click", () => toggleMenu())
});
